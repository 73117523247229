import React from 'react';
import { Send } from '@material-ui/icons';
import { twMerge } from 'tailwind-merge';
import styled from 'styled-components';

import { useChatTheme } from 'modules/shared/contexts/exercise-chats/ChatThemeContext';

export function MessageInput({
  message,
  onInputMessageChange,
  isSending,
  isMaxMessagesReached,
  onSend,
}) {
  const { colors } = useChatTheme();

  const handleKeyPress = e => {
    if (e.key === 'Enter' && !isSending) {
      onSend();
    }
  };

  return (
    <Container className="flex gap-2">
      <input
        type="text"
        value={message}
        onChange={e => onInputMessageChange(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder={
          isMaxMessagesReached
            ? 'Limite de mensagens atingido'
            : 'Digite sua dúvida...'
        }
        disabled={isSending || isMaxMessagesReached}
        className={twMerge(
          'flex-1 p-2 rounded-lg border border-transparent text-gray-900 h-10 disabled:opacity-50 disabled:cursor-not-allowed text-base bg-white',
          'focus:outline-none',
          colors.focus
        )}
        maxLength={280}
      />
      <button
        onClick={onSend}
        disabled={isSending || !message.trim() || isMaxMessagesReached}
        className={twMerge(
          'w-10 h-10 rounded-lg text-white flex items-center justify-center transition-colors disabled:opacity-50 disabled:cursor-not-allowed',
          colors.primary,
          colors.hover
        )}
      >
        <Send className={twMerge('h-5 w-5')} />
      </button>
    </Container>
  );
}

// reset styles
const Container = styled.div`
  input {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }
  }

  button {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border-radius: 0.375rem;
  }
`;
