import React, { useMemo } from 'react';

import { media, styled } from 'styles';
import BackButton from '../BackButton';
import landscape from '../../images/landscape.png';
import { Header, Img, Title } from './styles';
import TagAsFreeContent from 'modules/shared/components/TagAsFreeContent';
import { useUserContext } from 'providers/UserProvider';
import GuideBanner from '../GuideBanner';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';

export default function GuideHeader({
  name,
  color,
  textAlign,
  themeId = null,
  shouldHaveFreeTag = false,
  utmContent,
}) {
  const { user } = useUserContext();
  const { isBannerEligibleInGuidePage } = useMvpBooksEligible();

  const backLink = useMemo(() => {
    if (user?.isFromPrivateUniversityCampaign && themeId)
      return `/materias/completas/${themeId}`;

    return '/materias';
  }, [user, themeId]);

  return (
    <>
      {isBannerEligibleInGuidePage() && !media.isMobile() && (
        <GuideBanner utmContent={utmContent} />
      )}
      <Header backgroundColor={color}>
        <BackButton to={backLink} />
        {!user?.subscriber && shouldHaveFreeTag && (
          <CustomTagAsFreeContent text="GRÁTIS" />
        )}
        <Title textAlign={textAlign || 'center'}>{name}</Title>
        {isBannerEligibleInGuidePage() && media.isMobile() && <GuideBanner />}
        <Img src={landscape} />
      </Header>
    </>
  );
}

const CustomTagAsFreeContent = styled(TagAsFreeContent)`
  background-color: rgba(0, 0, 0, 0.8);
  width: 110px;
  height: 30.51px;
  margin: 0 auto;
  font-size: 19px;

  .star-icon {
    font-size: 22px;
    color: #f7ac3b;
  }

  @media ${media.mobile} {
    font-size: 18px;
  }
`;
