import React, { useCallback, useMemo } from 'react';
import { styled, media } from 'styles';
import {
  NoAccessDisclaimer,
  NoUnloggedAccessDisclaimer,
} from 'modules/shared/components/permission';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import {
  ChatFeedbackProvider,
  ChatLoaderProvider,
  ChatTrackEventsProvider,
} from 'modules/shared/contexts/exercise-chats';
import { useUserContext } from 'providers/UserProvider';
import { StepHeader } from './StepHeader';

export default function Solution({ blur, primaryColor, solvedByAi }) {
  const { user } = useUserContext();
  const { bookExercise, edition } = useBookEditionContext();

  const solutionSteps = useMemo(() => {
    return bookExercise?.lightSolution || [];
  }, [bookExercise]);

  const getStepClassName = useCallback(
    index => {
      if (index > 0 && blur) return 'blurs';
      else return '';
    },
    [blur]
  );

  if (!bookExercise) return null;

  return (
    <Container>
      <SolutionHeader primaryColor={primaryColor}>
        Resolução Passo a Passo
      </SolutionHeader>
      <div>
        <ChatWrapper
          isSolvedByAi={solvedByAi}
          bookExercise={bookExercise}
          edition={edition}
        >
          {solutionSteps.map((step, index) => (
            <div key={index} className={getStepClassName(index)}>
              <StepHeader
                solvedByIA={solvedByAi}
                stepIndex={index}
                primaryColor={primaryColor}
              />
              <MathjaxContentWithLatex
                text={index > 0 && blur ? solutionSteps[0] : step}
                dataCy={`book-solution-step-${index + 1}`}
                isLatex={!!solvedByAi}
              />
              {index === 0 && blur && (
                <>
                  {!user && (
                    <NoUnloggedAccessDisclaimer origin="Book Solution" />
                  )}
                  {user && <StyledNoAccessDisclaimer />}
                </>
              )}
            </div>
          ))}
        </ChatWrapper>
      </div>
    </Container>
  );
}

function ChatWrapper({ isSolvedByAi, bookExercise, edition, children }) {
  if (isSolvedByAi) {
    return children;
  }

  return (
    <ChatLoaderProvider
      exercise={{ ...bookExercise, exerciseType: 'BookExercise' }}
    >
      <ChatTrackEventsProvider
        location="Livros Resolvidos"
        exercise={bookExercise}
        numberOfSteps={bookExercise.lightSolution.length}
        openShowResolution
        anotherDefaultProperties={{
          'ID da Edição': edition.id,
          'ID do Livro': edition.bookId,
          'Nome do Livro (Amplitude)': edition.amplitudeName,
          'ID do Tópico': bookExercise.topic?.id ?? null,
          'Nome do Tópico': bookExercise.topic?.name ?? null,
          'ID do Assunto': bookExercise.topic?.subject?.id ?? null,
          'Nome do Assunto': bookExercise.topic?.subject?.name ?? null,
          'ID da Matéria': bookExercise.topic?.subject?.theme?.id ?? null,
          'Nome da Matéria': bookExercise.topic?.subject?.theme?.name ?? null,
        }}
      >
        <ChatFeedbackProvider>{children}</ChatFeedbackProvider>
      </ChatTrackEventsProvider>
    </ChatLoaderProvider>
  );
}

const Container = styled.div`
  padding: 0 30px;

  p,
  li {
    text-align: left;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media ${media.mobile} {
    padding: 0 10px;
  }
`;

const SolutionHeader = styled.h2`
  color: ${({ primaryColor }) => primaryColor};
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 20px;
  padding-bottom: 3px;
  border-bottom: 2px solid ${({ primaryColor }) => primaryColor};
`;

const StyledNoAccessDisclaimer = styled(NoAccessDisclaimer)`
  margin: 60px auto 20px auto;

  @media ${media.mobile} {
    margin: 20px auto;
  }
`;
