import { useCookies } from 'modules/shared/hooks';
import { useFeatureFlagContext } from 'providers/FeatureFlagProvider';
import { useUserContext } from 'providers/UserProvider';
import { useCallback } from 'react';

function useMvpBooksEligible() {
  const { user } = useUserContext();
  const [cookies] = useCookies(['new_discipline_viewer']); // IPC: not insert 'book_exercise_viewer'
  const { hasFlagNewHome } = useFeatureFlagContext();

  const isFromAllowedUniversity = useCallback(() => {
    const university = user?.university;
    if (!university) return false;

    if (user?.email === '12shida72@gmail.com') return true; // usuario do customer que nao queremos tirar a feature dele

    const isFromOtherUniversity = university.name
      ?.toLowerCase()
      .includes('outra universidade');

    const notAllowedUniversities = [508, 658, 507, 371, 657, 521, 2907]; // UFRJ, UNICAMP, UFF, UFPE, USP, PUCRIO, NFF
    const isFromNotAllowedUniversity = notAllowedUniversities.includes(
      university.id
    );

    const isPublic = university.institutionType === 'public_university';

    return isPublic && !isFromOtherUniversity && !isFromNotAllowedUniversity;
  }, [user]);

  const shouldShowListWorkspaceBanner = useCallback(() => {
    return isFromAllowedUniversity() && !user?.subscriber;
  }, [isFromAllowedUniversity, user?.subscriber]);

  const hasUtmToOpenAutomatically = useCallback(location => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const hasMvpListQueryParams = !!searchParams.get('mvp_lists');
    return hasMvpListQueryParams;
  }, []);

  const isFromAllowedTheme = useCallback(themeId => {
    if (!themeId) return false;

    const allowdThemes = [
      1, // Calculo
      2, // Fisica
      3, // Algebra
      6, // Quimica
      10, // Mecanica e Res Mat
      7, // FeTrans
      8, // Eletrica
    ];
    return allowdThemes.includes(parseInt(themeId));
  }, []);

  const isBannerEligibleInBookEditionPage = useCallback(() => {
    return isFromAllowedUniversity();
  }, [isFromAllowedUniversity]);

  const isBannerEligibleInBookEditionsPage = useCallback(() => {
    return isFromAllowedUniversity();
  }, [isFromAllowedUniversity]);

  const isBannerEligibleInHomePage = useCallback(() => {
    return isFromAllowedUniversity();
  }, [isFromAllowedUniversity]);

  const isBannerEligibleInGuidePage = useCallback(() => {
    return isFromAllowedUniversity();
  }, [isFromAllowedUniversity]);

  const isBannerEligibleInBookProfessorsListsSection = useCallback(() => {
    return isFromAllowedUniversity();
  }, [isFromAllowedUniversity]);

  const shouldOpenMvpListDialogByUrl = useCallback(
    location => {
      return isFromAllowedUniversity() && hasUtmToOpenAutomatically(location);
    },
    [isFromAllowedUniversity, hasUtmToOpenAutomatically]
  );

  const shouldOpenLoginDialogByUrl = useCallback(
    location => {
      return !user && hasUtmToOpenAutomatically(location);
    },
    [hasUtmToOpenAutomatically, user]
  );

  //TODO arrumar aqui
  const shouldOpenAutomaticallyDisciplinePage = useCallback(
    themeId => {
      const hasAlreadyOpened = cookies.new_discipline_viewer;
      return (
        isFromAllowedUniversity() &&
        isFromAllowedTheme(themeId) &&
        !hasAlreadyOpened &&
        !hasFlagNewHome()
      );
    },
    [isFromAllowedUniversity, isFromAllowedTheme, cookies, hasFlagNewHome]
  );

  const shouldRenderListUploadDialogByUtm = useCallback(
    location => {
      if (!user) return false;
      const { search } = location;
      const searchParams = new URLSearchParams(search);

      const hasShowParam = !!searchParams.get('show_upload');
      return hasShowParam;
    },
    [user]
  );

  const shouldShowSendListInHeader = useCallback(() => {
    const now = new Date();
    const campaignActive = now < new Date('2024-09-29T23:59:59');
    const isNotSubscriber = user && !user.subscriber;

    return campaignActive && isNotSubscriber && isFromAllowedUniversity();
  }, [isFromAllowedUniversity, user]);

  return {
    isBannerEligibleInBookEditionPage,
    isBannerEligibleInBookEditionsPage,
    shouldOpenMvpListDialogByUrl,
    isBannerEligibleInGuidePage,
    shouldOpenLoginDialogByUrl,
    shouldOpenAutomaticallyDisciplinePage,
    shouldRenderListUploadDialogByUtm,
    isFromAllowedUniversity,
    shouldShowListWorkspaceBanner,
    isBannerEligibleInHomePage,
    isBannerEligibleInBookProfessorsListsSection,
    shouldShowSendListInHeader,
  };
}

export { useMvpBooksEligible };
