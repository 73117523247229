import React, { useCallback } from 'react';
import { MdKeyboardArrowDown, MdUploadFile } from 'react-icons/md';
import Lottie from 'react-lottie';
import booksLottie from './assets/lottie/books.json';
import listsLottie from './assets/lottie/list.json';
import { Link } from 'react-router-dom';
import { media } from 'styles';
import { useTracker } from 'modules/shared/hooks';
import useListUploadDialog from 'modules/lists/hooks/useListUploadDialog';
import { useThemesContext } from 'providers/ThemesProvider';

export default function SolveQuestionsSection({ onListUpload }) {
  const { trackEvent } = useTracker();
  const { currentTheme: selectedTheme } = useThemesContext();

  const { triggerListFileInput, ListFileInput } = useListUploadDialog(
    listUploadUtms,
    onListUpload
  );

  const onClickList = useCallback(() => {
    triggerListFileInput();

    trackEvent('Home: Clicou pra enviar lista', {
      Matéria: selectedTheme?.name,
    });

    trackEvent('MVP Lista: Clicou Banner', {
      Onde: 'Matérias',
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.1',
    });
  }, [trackEvent, selectedTheme, triggerListFileInput]);

  const onClickBooks = useCallback(() => {
    trackEvent('Home: Clicou pra escolher livro', {
      Matéria: selectedTheme?.name,
    });
  }, [trackEvent, selectedTheme]);

  return (
    <div className="mt-4 md:mt-0 mx-auto w-11/12 md:w-full">
      {/* Render file input at the top level to avoid nesting issues */}
      {ListFileInput}

      <div
        className="flex flex-col md:flex-row rounded-lg text-white pr-3 pl-1 md:pl-3 gap-4 md:gap-2 mb-2 relative overflow-hidden py-4 md:py-0 h-auto md:h-36 shadow-sm"
        style={{
          backgroundColor: selectedTheme?.color,
        }}
      >
        <div className="flex flex-1 flex-shrink-0">
          <div className="flex items-center justify-center w-16 md:w-28 h-20 md:h-full">
            <BooksAnimatedIcon />
          </div>
          <div className="flex flex-col items-start justify-center w-full flex-1">
            <h2 className="text-base md:text-lg font-bold leading-5 md:leading-5 mb-2 md:mb-3">
              São questões de livro?
            </h2>
            <Link
              to="/livros-resolvidos"
              className="w-full"
              onClick={onClickBooks}
            >
              <div className="w-full md:w-72 pl-3 pr-2 md:px-4 py-2 bg-glass-white-bg rounded-lg flex items-center justify-between text-gray-800 text-sm border border-glass-white-border border-solid cursor-pointer hover:bg-glass-white-bg-hover hover:border-glass-white-bg-hover transition-all duration-300">
                <span>Escolha seu livro</span>
                <MdKeyboardArrowDown size={22} />
              </div>
            </Link>
          </div>
        </div>

        <div className="hidden md:block w-px bg-transparent-to-white-to-transparent h-4/5 self-center"></div>
        <div className="block md:hidden h-px w-4/5 bg-transparent-to-white-to-transparent-horizontal self-center"></div>

        <div className="flex flex-1 flex-shrink-0">
          <div className="relative flex items-center justify-center w-16 md:w-28 h-20 md:h-full">
            <ListsAnimatedIcon />
          </div>
          <div className="flex flex-col items-start justify-center w-full flex-1">
            <h2 className="text-base md:text-lg font-bold leading-5 md:leading-5 mb-2 md:mb-3">
              É uma lista de exercícios?
            </h2>
            <div
              className="w-full md:w-72 pl-3 pr-2 md:px-4 py-2 bg-glass-white-bg rounded-lg flex items-center justify-between text-gray-800 text-sm border border-glass-white-border border-solid cursor-pointer hover:bg-glass-white-bg-hover hover:border-glass-white-bg-hover transition-all duration-300 z-10"
              onClick={onClickList}
            >
              <span className="text-xs md:text-sm">
                Envie a <span className="underline">foto</span> ou{' '}
                <span className="underline">pdf</span> da sua lista
              </span>
              <MdUploadFile size={22} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const listUploadUtms = {
  utmSource: 'site_upload',
  utmMedium: 'banner',
  utmContent: 'pag_home',
};

function ListsAnimatedIcon() {
  const width = media.isMobile() ? 90 : 120;

  return (
    <>
      <AnimatedIcon
        width={width}
        animationData={listsLottie}
        style={{
          position: 'absolute',
          transform: media.isMobile()
            ? 'translate(-3px, 6px) rotate(-25deg)'
            : 'translate(-4px, 8px) rotate(-25deg)',
        }}
      />
      <AnimatedIcon
        width={width}
        animationData={listsLottie}
        style={{
          position: 'absolute',
          transform: media.isMobile()
            ? 'translate(3px, -6px) rotate(-15deg)'
            : 'translate(4px, -8px) rotate(-15deg)',
        }}
      />
    </>
  );
}

function BooksAnimatedIcon() {
  return (
    <AnimatedIcon
      width={media.isMobile() ? 80 : 100}
      animationData={booksLottie}
      style={{
        marginTop: '-15px',
      }}
    />
  );
}

function AnimatedIcon({ animationData, style, width }) {
  return (
    <Lottie
      width={width}
      options={{ loop: true, autoplay: true, animationData }}
      style={style}
    />
  );
}
