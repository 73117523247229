import React from 'react';

export function SelectButton({ children, isSelected, disabled, onClick }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`py-1 px-2 border border-gray-300 border-solid rounded-lg cursor-pointer bg-white select-none whitespace-nowrap ${
        isSelected ? 'bg-gray-400 text-white' : ''
      } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      {children}
    </button>
  );
}
