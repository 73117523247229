import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import { media, styled } from 'styles';
import {
  SolvedPercentage,
  Question,
  PageSkeleton,
  MainDialog,
  ListName,
} from '../components/ListWithStatementPage';
import { useContentLazyQueryImmutable } from 'modules/shared/hooks/requests';
import { ShareDrawer } from '../components/ShareDrawer';
import { ContainerButtons } from '../components/ContainerButtons';
import { useTrackEvents, useQuestions } from '../hooks';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { useToast } from 'modules/shared/hooks/useToast';
import { useHandleListOwner } from '../hooks/useHandleListOwner';
import ZeroPercentageDialog from '../components/ListWithStatementPage/dialogs/ZeroPercentDialog';
import MathpixLoader from 'providers/MathpixLoader';

export function ListWithStatementPage() {
  const { trackViewListPage, recordScreen } = useTrackEvents();
  const { listId } = useParams();
  const { solvedPercentage, solvedPercentageToBar } = useQuestions();
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [trackedViewEvent, setTrackedViewEvent] = useState(false);
  const { user } = useUserContext();
  const { unsetDialog, setDialog } = useDialogContext();
  const { setToastAfterReload } = useToast();
  const {
    setListIdInCookies,
    shouldSetUserIdToList,
    setUserIdToList,
  } = useHandleListOwner();
  const [hasGivenTrial, setHasGivenTrial] = useState(false);

  const [
    getList,
    {
      data: list,
      loading: listLoading,
      error: listError,
      mutate: invalidateListData,
    },
  ] = useContentLazyQueryImmutable(`/list-with-statements/${listId}`, {
    refreshInterval: shouldRefetch ? 4 * 1000 : 0, // 4s ou nunca
  });

  const isPending = useMemo(() => list && list.status === 'pending', [list]);

  const isIncompleteList = useMemo(() => {
    return list && list.status === 'incomplete';
  }, [list]);

  const handleError = useCallback(() => {
    setToastAfterReload(
      'Ops, não conseguimos identificar nenhuma questão nessa lista. Envie outra lista!',
      'error'
    );
    unsetDialog();
    window.location.href = '/resolver-lista?list_rejected=true';
  }, [setToastAfterReload, unsetDialog]);

  const processListAtributionAndTrial = useCallback(async () => {
    const trialGiven = await setUserIdToList(list.id);
    setHasGivenTrial(trialGiven);
  }, [list, setUserIdToList]);

  useEffect(() => {
    getList();
  }, [getList]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [list]);

  useEffect(() => {
    if (!list || isPending || trackedViewEvent) return;

    setTrackedViewEvent(true);
    trackViewListPage(list, solvedPercentage(list));
    recordScreen();
  }, [
    list,
    trackViewListPage,
    solvedPercentage,
    recordScreen,
    isPending,
    trackedViewEvent,
  ]);

  useEffect(() => {
    if (!listLoading && !isPending) setShouldRefetch(false);
  }, [isPending, listLoading]);

  useEffect(() => {
    // eslint-disable-next-line custom-rules/set-dialog
    const shouldSetLoginOrWaitDialog = !user || isPending;
    if (shouldSetLoginOrWaitDialog)
      // eslint-disable-next-line custom-rules/set-dialog
      setDialog(<MainDialog isListPending={isPending} list={list} />);

    const shouldOpenZeroPercentageDialog =
      !shouldSetLoginOrWaitDialog &&
      isIncompleteList &&
      solvedPercentage(list) === 0 &&
      !!Cookies.get('firstTimeInList');

    if (shouldOpenZeroPercentageDialog) {
      // eslint-disable-next-line custom-rules/set-dialog
      setDialog(<ZeroPercentageDialog list={list} />);
    }

    if (user && !isPending) {
      if (hasGivenTrial && !user.hasAccess) {
        window.location.reload();
      } else {
        if (!shouldSetLoginOrWaitDialog && !shouldOpenZeroPercentageDialog)
          unsetDialog();
      }
    }
  }, [
    hasGivenTrial,
    isIncompleteList,
    isPending,
    list,
    listLoading,
    setDialog,
    solvedPercentage,
    unsetDialog,
    user,
  ]);

  useEffect(() => {
    if (user && listError) handleError();
  }, [
    handleError,
    isPending,
    listError,
    listLoading,
    setDialog,
    unsetDialog,
    user,
  ]);

  useEffect(() => {
    if (!list) return;

    const shouldSetCookie = list.userId === null && isPending;
    if (shouldSetCookie) setListIdInCookies(list.id);
  }, [isPending, list, setListIdInCookies]);

  useEffect(() => {
    if (!list) return;

    if (shouldSetUserIdToList(list)) {
      processListAtributionAndTrial();
    }
  }, [
    isPending,
    list,
    processListAtributionAndTrial,
    setListIdInCookies,
    setUserIdToList,
    shouldSetUserIdToList,
  ]);

  useEffect(() => {
    if (isPending) {
      const expires = new Date(new Date().getTime() + 90000); // 150 sec from now
      Cookies.set('firstTimeInList', true, { expires });
    }
  }, [isPending]);

  if (!list || isPending) return <PageSkeleton />;

  return (
    <MathpixLoader>
      <Container>
        {!isIncompleteList && (
          <ListName list={list} onListMutate={invalidateListData} />
        )}
        <ContainerButtons
          onShare={() => setShowShareDrawer(true)}
          list={list}
          isIncompleteList={isIncompleteList}
        />
        {user?.email && user?.email.endsWith('@respondeai.com.br') && (
          <DownloadButton onClick={() => window.open(list.link, '_blank')}>
            Baixar Lista Original
          </DownloadButton>
        )}
        <SolvedPercentage
          percentage={isPending ? 0 : solvedPercentageToBar(list)}
        />
        <QuestionsContainer>
          {list.questions.map(question => (
            <Question question={question} list={list} key={question.id} />
          ))}
        </QuestionsContainer>

        <ShareDrawer
          open={showShareDrawer}
          onClose={() => setShowShareDrawer(false)}
        />
      </Container>
    </MathpixLoader>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 30px 40px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
`;

const DownloadButton = styled.div`
  padding: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  border: 1.5px solid steelblue;
`;
