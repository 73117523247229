import React, { useState } from 'react';
import { useChatTrackEvents } from '../../../contexts/exercise-chats';
import { ChatBox } from './ChatBox';
import { StepTitle } from './StepTitle';
import { ChatThemeProvider } from '../../../contexts/exercise-chats';

export function Step({ index, theme = 'orange' }) {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { trackFeatureClick, trackFeatureClose } = useChatTrackEvents();

  const handleInfoBoxToggle = () => {
    if (!showInfoBox) {
      trackFeatureClick(index);
    } else {
      trackFeatureClose(index);
    }
    setShowInfoBox(!showInfoBox);
  };

  return (
    <ChatThemeProvider theme={theme}>
      <div className="flex flex-col gap-4">
        <StepTitle
          stepIndex={index}
          showInfoBox={showInfoBox}
          onInfoBoxToggle={handleInfoBoxToggle}
        />
        {showInfoBox && <ChatBox index={index} />}
      </div>
    </ChatThemeProvider>
  );
}
