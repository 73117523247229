import React from 'react';
import { useUserContext } from 'providers/UserProvider';
import { twMerge } from 'tailwind-merge';
import { useChatTheme } from 'modules/shared/contexts/exercise-chats';

export function UserMessage({ message }) {
  const { user } = useUserContext();
  const { colors } = useChatTheme();

  return (
    <div className={twMerge('flex items-start gap-0.5 justify-end')}>
      <div
        className={twMerge(
          'p-3 rounded-lg md:max-w-xs max-w-full text-white leading-tight text-base',
          colors.primary
        )}
      >
        {message}
      </div>
      <div
        className={twMerge('w-12 h-12 flex flex-col items-center ml-1 mt-2')}
      >
        <img
          src={user.pictureUrl}
          className={twMerge('w-8 h-8 rounded-full')}
        />
        <p
          className={twMerge(
            'text-xs leading-none font-normal text-black mt-1 text-center'
          )}
        >
          Você
        </p>
      </div>
    </div>
  );
}
