import React from 'react';

import dexterAiImg from 'images/mvp/dexter-ai.png';

export function SmallDexterIAProfile() {
  return (
    <div className="w-12 h-12 flex flex-col items-center mr-1">
      <img src={dexterAiImg} className="w-8 h-8" />
      <p className="text-xs leading-none font-normal text-black mt-1 text-center">
        Dexter IA
      </p>
    </div>
  );
}
