import React, { createContext, useContext } from 'react';

const ChatThemeContext = createContext({
  theme: 'orange',
});

export function ChatThemeProvider({ children, theme = 'orange' }) {
  const themeColors = {
    orange: {
      primary: 'bg-orange-400',
      secondary: 'bg-orange-100',
      background: 'bg-orange-50',
      border: 'border-orange-500',
      text: 'text-orange-400',
      hover: 'hover:bg-orange-500',
      focus: 'focus:border-orange-500',
    },
    'green-blue': {
      primary: 'bg-green-blue-400',
      secondary: 'bg-green-blue-100',
      background: 'bg-green-blue-50',
      border: 'border-green-blue-400',
      text: 'text-green-blue-400',
      hover: 'hover:bg-green-blue-500',
      focus: 'focus:border-green-blue-400',
    },
  };

  return (
    <ChatThemeContext.Provider value={{ theme, colors: themeColors[theme] }}>
      {children}
    </ChatThemeContext.Provider>
  );
}

export function useChatTheme() {
  const context = useContext(ChatThemeContext);
  if (!context) {
    throw new Error('useChatTheme must be used within a ChatThemeProvider');
  }
  return context;
}
