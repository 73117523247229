import React, { useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';
import { useDialogContext } from 'providers/DialogProvider';
import { media } from 'styles';
import ListUploader from './ListUploader';
import BooksCTA from './BooksCTA';
import MobileDialog from './MobileDIalog';
import leftArrowPath from './assets/left-arrow.svg';
import rightArrowPath from './assets/right-arrow.svg';
import bookIcon from './assets/book-icon.svg';
import uploadIcon from './assets/upload-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setDialog } = useDialogContext();

  const { ref, inView: mainHeaderIsVisible } = useInView({
    threshold: 0.1,
    initialInView: true,
  });

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  function openUploadDialog() {
    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent: 'pag_exercicio_buscado',
    };

    setDialog(<UploadBookListDialog origin="Exercicio Buscado" utms={utms} />);
  }

  return (
    <>
      <DesktopHeader ref={ref}>
        <TopBanner>
          <Title>Ainda perde tempo procurando resolução no Google? 😰</Title>
          <Subtitle>
            Encontre seus exercícios resolvidos e explicados num passe de
            mágica!
          </Subtitle>
        </TopBanner>

        <CallToActionsContainer>
          <CallToAction>
            <img src={leftArrowPath} />
            <CallToActionTitle>É uma questão de livro? 📚</CallToActionTitle>
            <BooksCTA />
          </CallToAction>
          <CallToAction>
            <img src={rightArrowPath} />
            <CallToActionTitle>É uma lista de exercícios? 📝</CallToActionTitle>
            <ListUploader />
          </CallToAction>
        </CallToActionsContainer>

        <StickyHeader visible={!mainHeaderIsVisible}>
          <StickyHeaderTitle>
            Encontre seus exercícios resolvidos e explicados num passe de
            mágica!
          </StickyHeaderTitle>
          <StickHeaderButtonsContainer>
            <StickHeaderButton onClick={scrollToTop}>
              <img src={bookIcon} /> Outra questão de livro
            </StickHeaderButton>
            <StickHeaderButton onClick={openUploadDialog}>
              <img src={uploadIcon} />
              Envie questão ou lista
            </StickHeaderButton>
          </StickHeaderButtonsContainer>
        </StickyHeader>
      </DesktopHeader>

      <MobileHeader>
        Encontre seus exercícios resolvidos e explicados num passe de mágica!
        <MobileHeaderButton onClick={() => setIsModalOpen(true)}>
          Encontre qualquer questão <FontAwesomeIcon icon={faChevronRight} />
        </MobileHeaderButton>
        {isModalOpen && (
          <MobileDialog onCloseRequest={() => setIsModalOpen(false)} />
        )}
      </MobileHeader>
    </>
  );
}

const DesktopHeader = styled.div`
  width: 100%;
  text-align: center;
  color: #000;
  padding-bottom: 40px;

  @media ${media.mobile} {
    display: none;
  }
`;

const TopBanner = styled.div`
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, #0c8c7d 0%, rgba(20, 185, 166, 0) 100%);
  padding-top: 50px;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
`;

const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin-top: 5px;
`;

const CallToActionsContainer = styled.div`
  width: 960px;
  max-width: 95%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: -75px auto 0 auto;
`;

const CallToAction = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > img {
    width: 44px;
  }
`;

const CallToActionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0 10px 0;
`;

const StickyHeader = styled.div`
  position: fixed;
  top: -100px;
  background: #14b9a6;
  width: 100%;
  height: 100px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  transition: top 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ visible }) => visible && 'top: 0px;'}

  @media ${media.mobile} {
    display: none;
  }
`;

const StickyHeaderTitle = styled.h2`
  font-size: 18px;
  color: white;
  text-align: center;
`;

const StickHeaderButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const StickHeaderButton = styled.div`
  padding: 8px 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-transform: uppercase;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
`;

const MobileHeader = styled.div`
  display: none;
  padding: 90px 10px 30px 10px;
  text-align: center;
  font-size: 18px;
  background: #14b9a6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 20px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  margin-bottom: 20px;

  @media ${media.mobile} {
    display: flex;
  }
`;

const MobileHeaderButton = styled.div`
  color: white;
  background: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  padding: 10px 30px;
  border-radius: 50px;
  font-size: 16px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;
