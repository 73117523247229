import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { twMerge } from 'tailwind-merge';
import { ChatMessageFeedback } from './Feedback';
import { useChatTheme } from 'modules/shared/contexts/exercise-chats';

import { SmallDexterIAProfile } from './SmallDexterIAProfile';
import { RawMathpixMarkdownContent } from 'modules/shared/components/generic';

export function AssistantMessage({
  content,
  messageId,
  messageIndex,
  feedback,
}) {
  const { colors } = useChatTheme();

  return (
    <div className={twMerge('flex items-start gap-0.5 w-full')}>
      <SmallDexterIAProfile />
      <div className={twMerge('flex gap-2 flex-col flex-1 min-w-0')}>
        <div
          className={twMerge(
            'p-1 rounded-lg w-full text-gray-900 leading-tight text-base break-words min-w-0',
            colors.secondary
          )}
        >
          <span className="hide-scrollbar">
            <CompactStyleWrapper>
              <RawMathpixMarkdownContent text={content} />
            </CompactStyleWrapper>
          </span>
        </div>
        {messageId && (
          <ChatMessageFeedback
            messageId={messageId}
            messageIndex={messageIndex}
            feedback={feedback}
          />
        )}
      </div>
    </div>
  );
}

const CompactStyleWrapper = styled.div`
  color: inherit;
  position: relative;
  width: 100%;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  #content {
    width: 100%;
    min-width: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;

    #preview {
      padding: 0 !important;
      width: 100%;
      min-width: 0;
      overflow-wrap: break-word;
      word-wrap: break-word;
      line-height: 2;
    }
  }

  h1,
  h2,
  div,
  p {
    margin: 0px 0px 8px 0px;
    padding: 0;
    line-height: 1.5;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100%;
    min-width: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  h1,
  div,
  h3 {
    font-weight: 400;
  }

  ul,
  ol {
    margin-left: 5px;
    padding-left: 5px;
    margin-bottom: 8px;
    max-width: 100%;
    min-width: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1,
  b {
    color: #444;
  }

  b {
    font-weight: 700;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 25px;
    line-height: 33px;
  }

  h2 {
    font-size: 1.2em;
    margin-top: 10px;
    line-height: 31px;
    padding-bottom: 30px;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;
  }

  ol {
    list-style-type: decimal;
    list-style-position: outside;
  }

  ul li {
    list-style-type: disc;
  }

  @media ${media.mobile} {
    .MJX-TEX {
      max-width: 100%;
      min-width: 0;
      overflow-x: auto;
      overflow-y: hidden;
      display: inline-block;
      vertical-align: middle;
    }

    mjx-container {
      min-width: 0 !important;
      max-width: 100% !important;
      overflow-x: auto;
      overflow-y: hidden;
      display: inline-block !important;
      vertical-align: middle;
    }
  }

  mjx-container {
    text-align: start !important;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100% !important;
    min-width: 0 !important;
  }

  p,
  ol,
  ul,
  h1,
  img {
    margin-top: 5px;
  }

  /* Chrome, Safari, Opera */
  .hide-scrollbar *::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  .hide-scrollbar * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
`;
