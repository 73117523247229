import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useRef, useState } from 'react';
import ListUploadDialog from '../dialogs/ListUploadDialog/ListUploadDialog';
import { useListWithStatementTracker } from 'modules/mvp/hooks/useListWithStatementTracker';
import { useThemesContext } from 'providers/ThemesProvider';

export default function useListUploadDialog(utms, onListUpload = () => {}) {
  const fileInputRef = useRef(null);
  const { setDialog } = useDialogContext();
  const { trackUploadFile } = useListWithStatementTracker();

  const { themes, currentTheme: defaultTheme } = useThemesContext();

  const clearFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }, []);

  const trackFileSelected = useCallback(
    file => {
      trackUploadFile({
        fileName: file.name,
        fileType: file.type,
        themeName: defaultTheme?.name,
      });
    },
    [defaultTheme, trackUploadFile]
  );

  const openDialog = useCallback(
    file => {
      if (!file) return;

      setDialog(
        <ListUploadDialog
          file={file}
          themes={themes}
          defaultTheme={defaultTheme}
          onClose={clearFile}
          onListUpload={onListUpload}
          utms={utms}
        />
      );
    },
    [setDialog, clearFile, defaultTheme, themes, utms, onListUpload]
  );

  const onSelectFile = useCallback(
    e => {
      const file = e?.target?.files?.[0];
      if (!file) return;

      openDialog(file);
      trackFileSelected(file);
    },
    [openDialog, trackFileSelected]
  );

  const triggerListFileInput = useCallback(() => {
    // Small delay to ensure state is updated
    setTimeout(() => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }, 0);
  }, [fileInputRef]);

  const ListFileInput = (
    <input
      ref={fileInputRef}
      type="file"
      style={{ display: 'none', position: 'absolute' }}
      onChange={onSelectFile}
      accept="image/jpeg, image/png, image/jpg, application/pdf"
      multiple={false}
      onClick={e => e.stopPropagation()}
    />
  );

  return {
    ListFileInput,
    triggerListFileInput,
  };
}
