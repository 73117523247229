import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

import useContentRestRequest from 'modules/shared/hooks/requests/useContentRestRequest';
import { useUserContext } from 'providers/UserProvider';
import { useTestAB } from 'modules/shared/hooks';

const ChatLoaderContext = createContext({});

const exerciseTypeToSlug = {
  BookExercise: 'book-exercises',
  ListExercise: 'list-exercises',
  Exercise: 'exercises',
};

export function ChatLoaderProvider({ children, exercise }) {
  const isEligible = useChatEligibility();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chats, setChats] = useState([]);
  const { sendGetRequest, sendPostRequest } = useContentRestRequest();

  console.log('exercise', exercise);

  const slug = exerciseTypeToSlug[exercise.exerciseType];
  const exerciseId = exercise.id;

  const loadChatMessages = useCallback(
    async (isInitialLoad = false) => {
      if (!isEligible || !slug) return;

      try {
        if (isInitialLoad) {
          setIsLoading(true);
        }
        setError(null);

        const { data } = await sendGetRequest(
          `/exercise-chats/${slug}/${exerciseId}/chats`
        );
        setChats(data);
      } catch (err) {
        setError(err.message || 'Failed to load chat messages');
      } finally {
        setIsLoading(false);
      }
    },
    [sendGetRequest, isEligible, slug, exerciseId]
  );

  const postMessage = useCallback(
    async (stepIndex, message) => {
      if (!isEligible) return;

      try {
        setError(null);
        const result = await sendPostRequest(
          `/exercise-chats/${slug}/${exerciseId}/chats/messages`,
          { stepIndex, message }
        );
        loadChatMessages(false);

        return result;
      } catch (err) {
        setError(err.message || 'Failed to send message');
        throw err;
      }
    },
    [sendPostRequest, loadChatMessages, isEligible, slug, exerciseId]
  );

  const invalidate = useCallback(() => {
    if (exerciseId) {
      loadChatMessages(false);
    }
  }, [exerciseId, loadChatMessages]);

  useEffect(() => {
    if (exerciseId) {
      loadChatMessages(true);
    }
  }, [exerciseId, loadChatMessages]);

  return (
    <ChatLoaderContext.Provider
      value={{
        isLoading,
        error,
        chats,
        invalidate,
        postMessage,
        isEligible,
      }}
    >
      {children}
    </ChatLoaderContext.Provider>
  );
}

export function useChatLoader() {
  const context = useContext(ChatLoaderContext);

  if (!context) {
    throw new Error('useChatLoader must be used within a ChatLoaderProvider');
  }

  return context;
}

const CAMPAIGN_NAME = 'exercise_chat';

function useChatEligibility() {
  const { user } = useUserContext();
  const { setABTestRandomCookie } = useTestAB();
  const [isEligible, setIsEligible] = useState(false);

  useEffect(() => {
    const isRespondeAiUser = user?.email?.includes('@respondeai.com.br');
    if (isRespondeAiUser) {
      setIsEligible(true);
      return;
    }

    const isSubscriber = user?.hasAccess;
    if (!isSubscriber) {
      setIsEligible(false);
      return;
    }

    const isOnTest = setABTestRandomCookie(CAMPAIGN_NAME, 10);
    setIsEligible(isOnTest);
  }, [user, setABTestRandomCookie]);

  return isEligible;
}

/**
 * @typedef {Object} Chat
 * @property {number} id - The unique identifier of the chat
 * @property {number} exerciseId - The ID of the exercise
 * @property {string} exerciseType - The type of exercise
 */

/**
 * @typedef {Object} Message
 * @property {string} role - The role of the message sender ('user' or 'assistant')
 * @property {string} content - The content of the message
 * @property {string} createdAt - The timestamp when the message was created
 */

/**
 * @typedef {Object} ChatWithMessages
 * @property {Chat} chat - The chat object
 * @property {Message[]} messages - Array of messages in the chat
 */
