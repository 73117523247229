import React from 'react';
import { twMerge } from 'tailwind-merge';

import { SmallDexterIAProfile } from './AssistantMessage/SmallDexterIAProfile';
import { useChatTheme } from 'modules/shared/contexts/exercise-chats';

export function LoadingAssistantMessage() {
  const { colors } = useChatTheme();

  return (
    <div className="flex items-end gap-0.5">
      <SmallDexterIAProfile />
      <div
        className={twMerge(
          'h-10 p-1 rounded-lg max-w-sm text-gray-900 leading-tight text-base flex items-center',
          colors.background
        )}
      >
        <div className="flex gap-1">
          <div
            className={twMerge(
              'w-2 h-2 text-gray-900 opacity-50 rounded-full animate-bounce',
              colors.primary
            )}
            style={{ animationDelay: '0ms' }}
          />
          <div
            className={twMerge(
              'w-2 h-2 text-gray-900 opacity-50 rounded-full animate-bounce',
              colors.primary
            )}
            style={{ animationDelay: '150ms' }}
          />
          <div
            className={twMerge(
              'w-2 h-2 text-gray-900 opacity-50 rounded-full animate-bounce',
              colors.primary
            )}
            style={{ animationDelay: '300ms' }}
          />
        </div>
      </div>
    </div>
  );
}
