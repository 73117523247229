import React, { useState } from 'react';
import { useDialogContext } from 'providers/DialogProvider';
import { SelectButton } from './SelectButton';
import styled from 'styled-components';
import { useToast } from 'modules/shared/hooks';

const CORRECTNESS_OPTIONS = ['Não', 'Acho que não', 'Acho que sim', 'Sim'];
const CLARITY_OPTIONS = ['Não', 'Mais ou menos', 'Sim'];

export function FeedbackDialog({
  onCorrectnessSelect,
  onClaritySelect,
  onDetailsSubmit,
  onSubmit,
  onClose,
}) {
  const { unsetDialog } = useDialogContext();
  const { successToast } = useToast();
  const [selectedCorrectness, setSelectedCorrectness] = useState(null);
  const [selectedClarity, setSelectedClarity] = useState(null);
  const [details, setDetails] = useState('');

  const handleCorrectnessSelect = index => {
    setSelectedCorrectness(index);
    onCorrectnessSelect?.(CORRECTNESS_OPTIONS[index], index);
  };

  const handleClaritySelect = index => {
    setSelectedClarity(index);
    onClaritySelect?.(CLARITY_OPTIONS[index], index);
  };

  const handleSubmit = () => {
    const trimmedDetails = details.trim();

    if (trimmedDetails) {
      onDetailsSubmit?.(trimmedDetails);
    }

    onSubmit?.(
      selectedCorrectness !== null
        ? CORRECTNESS_OPTIONS[selectedCorrectness]
        : null,
      selectedCorrectness,
      selectedClarity !== null ? CLARITY_OPTIONS[selectedClarity] : null,
      selectedClarity,
      trimmedDetails || null
    );

    successToast('Obrigado pelo Feedback 🧡');
    onClose?.();
    unsetDialog();
  };

  const isSubmitEnabled =
    selectedCorrectness !== null && selectedClarity !== null;

  return (
    <Container className="w-full h-full flex justify-center items-center bg-white bg-opacity-50">
      <div className="bg-white rounded-2xl w-11/12 max-w-2xl p-6 shadow-lg text-left text-gray-900">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">
            Nos ajude a melhorar essa funcionalidade pra você
          </h2>
        </div>

        <div className="mb-6">
          <h3 className="text-base font-medium mb-3">
            A resposta do Dexter IA estava correta?
          </h3>
          <div className="flex flex-wrap gap-1">
            {CORRECTNESS_OPTIONS.map((option, index) => (
              <SelectButton
                key={index}
                onClick={() => handleCorrectnessSelect(index)}
                isSelected={selectedCorrectness === index}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-base font-medium mb-3">
            Você entendeu facilmente a resposta que o Dexter IA deu?
          </h3>
          <div className="flex flex-wrap gap-1">
            {CLARITY_OPTIONS.map((option, index) => (
              <SelectButton
                key={index}
                onClick={() => handleClaritySelect(index)}
                isSelected={selectedClarity === index}
              >
                {option}
              </SelectButton>
            ))}
          </div>
        </div>

        <div className="mb-6">
          <h3 className="text-base font-medium mb-3">
            (Opcional) Quer nos dar mais detalhes do que achou desta resposta ou
            nos ajudar a melhorar esta funcionalidade?
          </h3>
          <textarea
            value={details}
            onChange={e => setDetails(e.target.value)}
            placeholder="Fique à vontade para adicionar detalhes específicos"
            className="w-full h-24 p-3 border-solid border border-gray-200 rounded-lg resize-y text-base placeholder-gray-400 font-sans"
          />
        </div>

        <button
          disabled={!isSubmitEnabled}
          onClick={handleSubmit}
          className={`w-full font-bold py-3 px-4 rounded-lg text-base text-white transition-colors
            ${
              isSubmitEnabled
                ? 'bg-orange-400 hover:bg-orange-500 cursor-pointer'
                : 'bg-gray-300 cursor-not-allowed'
            }`}
        >
          Enviar
        </button>
      </div>
    </Container>
  );
}

// reset styles
const Container = styled.div`
  input,
  textarea {
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    margin: 0;

    &:focus {
      outline: none;
    }
  }

  button {
    appearance: none;
    background: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    border-radius: 0.375rem;
  }
`;
