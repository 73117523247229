import React from 'react';
import { Step } from 'modules/shared/components/exercise-chats/Step';
import styled from 'styled-components';
import { useChatLoader } from 'modules/shared/contexts/exercise-chats';

export function StepHeader({ solvedByIA, stepIndex, primaryColor }) {
  const { isEligible } = useChatLoader();

  if (solvedByIA || !isEligible) {
    return (
      <DefaultStepHeader primaryColor={primaryColor}>
        Passo {stepIndex + 1}
      </DefaultStepHeader>
    );
  }

  return <Step index={stepIndex} theme="green-blue" />;
}

const DefaultStepHeader = styled.h2`
  color: ${({ primaryColor }) => primaryColor};
  font-size: 1.3em;
  margin-top: 40px;
`;
